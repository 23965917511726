export function percentsNumber(number, places = undefined) {
  if (number === undefined || number === null) return number;

  number = Number(number) * 100;
  if (places === undefined) {
    const absNumber = Math.abs(number);
    places = 0;
    if (0 < absNumber && absNumber < 1) {
      places = 2;
    } else if (1 <= absNumber && absNumber < 10) {
      places = 1;
    }
  }

  return abbreviateNumber(number, places, places);
}

export function extractNumberSign(value) {
  return value > 0 ? "+" : value < 0 ? "-" : "";
}

export function validateEmail(email) {
  const regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regEmail.test(email);
};

export function abbreviateNumber(
  number,
  maxPlaces,
  forcePlaces = 2,
  forceLetter = false
) {
  number = Number(number);
  forceLetter = forceLetter || false;
  if (forceLetter !== false) {
    return annotateNumber(number, maxPlaces, forcePlaces, forceLetter);
  }
  var abbr;
  if (Math.abs(number) >= 1e12) {
    abbr = "T";
  } else if (Math.abs(number) >= 1e9) {
    abbr = "B";
  } else if (Math.abs(number) >= 1e6) {
    abbr = "M";
  } else if (Math.abs(number) >= 1e3) {
    abbr = "K";
  } else {
    abbr = "";
  }
  return annotateNumber(number, maxPlaces, forcePlaces, abbr);
}

function annotateNumber(number, maxPlaces, forcePlaces, abbr) {
  // set places to false to not round
  var rounded = 0;
  switch (abbr) {
    case "T":
      rounded = number / 1e12;
      break;
    case "B":
      rounded = number / 1e9;
      break;
    case "M":
      rounded = number / 1e6;
      break;
    case "K":
      rounded = number / 1e3;
      break;
    case "":
      rounded = number;
      break;
  }
  if (maxPlaces !== false) {
    var test = new RegExp("\\.\\d{" + (maxPlaces + 1) + ",}$");
    if (test.test("" + rounded)) {
      rounded = rounded.toFixed(maxPlaces);
    }
  }
  if (forcePlaces !== false) {
    rounded = Number(rounded).toFixed(forcePlaces);
  }
  return rounded + abbr;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function setCustomerNameInString(value, customerName) {
  customerName = customerName || "mb"; // if customerName is undefined, set it to "mb"
  if (customerName == "mb") customerName = "Your"; // if customerName is "mb", should replace with "Your" in string

  return value !== undefined && customerName !== undefined
    ? value.replace("{customer}", customerName)
    : value;
}

export function setFrequencyInString(value, frequency) {
  const frequencyOptions = { 0: "Monthly", 1: "Weekly", 2: "Daily" };

  frequency =
    frequency && 0 <= frequency <= 2
      ? frequencyOptions[frequency]
      : frequencyOptions[0];

  return value !== undefined ? value.replace("{frequency}", frequency) : value;
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getPercent(part, total, roundTo = 2) {
  return parseFloat((part / total) * 100).toFixed(roundTo) + "%";
}

export function isFunction(obj) {
  return typeof obj === "function";
}

export function removeEmptyLines(input) {
  return input
    .split(/\r?\n/) // Split input text into an array of lines
    .filter((line) => line.trim() !== "") // Filter out lines that are empty or contain only whitespace
    .join("\n"); // Join line array into a string
}

export function extractTextFromElement(elem) {
  if (!elem) {
    return "";
  }
  if (typeof elem === "string") {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(extractTextFromElement).join("");
  }
  return extractTextFromElement(children);
}

export function arrayToChunks(array, chunkSize = 10) {
  let res = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    res.push(array.slice(i, i + chunkSize));
  }
  return res;
}

export function objectsEqual(o1, o2) {
  if (o2 === null && o1 !== null) return false;
  return o1 !== null && typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 !== null &&
      Array.isArray(o1) &&
      Array.isArray(o2) &&
      !o1.length &&
      !o2.length
    ? true
    : o1 === o2;
}

export function objectToArray(originalObject, valueKey) {
  const transformedArray = [];

  for (const key in originalObject) {
    for (const value in originalObject[key]) {
      let valueEntry = transformedArray.find(
        (entry) => entry[valueKey] === value
      );
      if (!valueEntry) {
        valueEntry = { [valueKey]: value };
        transformedArray.push(valueEntry);
      }
      valueEntry[key] = originalObject[key][value];
    }
  }
  return transformedArray;
}


const currencyMap = new Map([
  // South Korea
  ["emart.ssg.com", "₩"],

  // Philippines
  ["gocart.ph", "₱"],

  // India
  ["amazon.in", "₹"],
  ["flipkart.com", "₹"],

  // Brazil (BRL - Brazilian Real)
  ["amazon.com.br", "R$"],
  ["kabum.com.br", "R$"],
  ["mercadolivre.com.br", "R$"],

  // Canada (CAD - Canadian Dollar)
  ["amazon.ca", "C$"],
  ["bestbuy.ca", "C$"],
  ["staples.ca", "C$"],
  ["walmart.ca", "C$"],
  ["canadacomputers.com", "C$"],

  // Switzerland (CHF - Swiss Franc)
  ["brack.ch", "CHF"],
  ["digitec.ch", "CHF"],
  ["interdiscount.ch", "CHF"],
  ["mediamarkt.ch", "CHF"],

  // Germany (EUR - Euro)
  ["alternate.de", "€"],
  ["amazon.de", "€"],
  ["conrad.de", "€"],
  ["cyberport.de", "€"],
  ["expert.de", "€"],
  ["galaxus.de", "€"],
  ["mediamarkt.de", "€"],
  ["notebooksbilliger.de", "€"],
  ["otto.de", "€"],

  // Denmark (DKK - Danish Krone)
  ["elgiganten.dk", "DKK"],
  ["komplett.dk", "DKK"],
  ["proshop.dk", "DKK"],

  // Spain (EUR - Euro)
  ["amazon.es", "€"],
  ["mediamarkt.es", "€"],
  ["pccomponentes.com", "€"],

  // France (EUR - Euro)
  ["amazon.fr", "€"],
  ["boulanger.com", "€"],
  ["darty.com", "€"],
  ["fnac.com", "€"],
  ["ldlc.com", "€"],

  // United Kingdom (GBP - British Pound)
  ["amazon.co.uk", "£"],
  ["currys.co.uk", "£"],
  ["argos.co.uk", "£"],
  ["very.co.uk", "£"],

  // Italy (EUR - Euro)
  ["amazon.it", "€"],
  ["mediaworld.it", "€"],
  ["unieuro.it", "€"],

  // Japan (JPY - Japanese Yen)
  ["amazon.co.jp", "¥"],

  // Mexico (MXN - Mexican Peso)
  ["amazon.com.mx", "MX$"],
  ["liverpool.com.mx", "MX$"],
  ["mercadolibre.com.mx", "MX$"],
  ["officedepot.com.mx", "MX$"],

  // Netherlands (EUR - Euro)
  ["alternate.nl", "€"],
  ["amazon.nl", "€"],
  ["bol.com", "€"],
  ["coolblue.nl", "€"],
  ["mediamarkt.nl", "€"],

  // Poland (PLN - Polish Zloty)
  ["amazon.pl", "zł"],
  ["euro.com.pl", "zł"],
  ["mediaexpert.pl", "zł"],
  ["mediamarkt.pl", "zł"],
  ["morele.net", "zł"],
  ["x-kom.pl", "zł"]
]);


export function getCurrencySignByDomain(domain) {
  return currencyMap.get(domain) || "$";
}
export function splitString(str, maxSize) {
  const result = [];
  let start = 0;

  while (start < str.length) {
    result.push(str.substring(start, start + maxSize));
    start += maxSize;
  }

  return result;
}

function genericComparator(a, b) {
  // Handle null and undefined by sorting them to the end
  if (a == null) return 1;
  if (b == null) return -1;

  // Sort by type: numbers, booleans, and strings
  const typeA = typeof a;
  const typeB = typeof b;

  if (typeA === typeB) {
    if (typeA === "number") return a - b;
    if (typeA === "boolean") return a === b ? 0 : a ? 1 : -1;
    if (typeA === "string") return a.localeCompare(b);
  }

  // If types differ, sort by type precedence: number < boolean < string < other
  const typeOrder = { number: 1, boolean: 2, string: 3 };
  return (typeOrder[typeA] || 4) - (typeOrder[typeB] || 4);
}

export function getSortedObjectKeysByValues(obj) {
  return Object.keys(obj).sort((a, b) => genericComparator(obj[a], obj[b]));
}

export function sortObject(obj) {
  return Object.fromEntries(
    Object.entries(obj).sort(([keyA], [keyB]) => genericComparator(keyA, keyB))
  );
}
